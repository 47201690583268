<template>
  <v-app data-app>
    <v-main>
      <div class="app-workflow">
        <a href="/"><strong>Home</strong></a>
        <br /><br />
        <a href="#" @click="showDefault = true"
          ><strong>Workflow Builder</strong>, select your own trigger</a
        >
        <br /><br />
        <p>
          <a href="#" @click="showCustom = true"
            >Workflow Builder with <strong>preconfigured data</strong> fields</a
          >
        </p>

        <workflow-sdk
          v-if="showDefault"
          @destroy="showDefault = false"
          v-bind="defaultOptions"
        ></workflow-sdk>
        <workflow-sdk
          v-if="showCustom"
          @destroy="showCustom = false"
          v-bind="customOptions"
        ></workflow-sdk>
      </div>
    </v-main>
  </v-app>
</template>

<script>
import workflowSdk from "./init.vue";
export default {
  components: {
    workflowSdk,
  },
  data() {
    return {
      showDefault: false,
      showCustom: false,
      defaultOptions: {
        apiKey: "HYPN_ZmZhOWNkYTRiNTY4MzI4OC1rYWxva2Fsb0Bwcm9jZXNzLmZpbmFuY2U=",
        organizationId: "2",
        triggers: ["Form", "Invoice", "Payment"],
      },

      customOptions: {
        apiKey: "HYPN_ZmZhOWNkYTRiNTY4MzI4OC1rYWxva2Fsb0Bwcm9jZXNzLmZpbmFuY2U=",
        organizationId: "2",
        triggers: "Form",
        form: "145",
      },
    };
  },
};
</script>
<style>
.app-workflow {
  padding: 20px;
}
</style>
